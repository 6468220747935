@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  src: url("fonts/Poppins/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500;
  src: url("fonts/Poppins/Poppins-Medium.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  src: url("fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  src: url("fonts/Poppins/Poppins-Bold.ttf") format("truetype");
}

body {
  margin: 0;
  font-family: "Poppins";
  font-size: 14px;
  color: #383838;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
input.withButton::-webkit-outer-spin-button,
input.withButton::-webkit-inner-spin-button {
  -webkit-appearance: button;
}

/* Firefox */
input.withButton[type="number"] {
  -moz-appearance: button;
}

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu",
    "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
